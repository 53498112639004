
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DialogSection extends Vue {
  @Prop()
  private readonly title!: string
}
