































































import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'

import { debounce } from '@/modules/shared/utils/functions'

import BacteriaFilterService from '@/modules/shared/services/BacteriaFilterService'

import BacteriaIcon from '@/modules/shared/components/icons/BacteriaIcon.vue'

@Component({
  components: {
    BacteriaIcon,
  },
})
export default class ResistomeAutocomplete extends Vue {
  $refs!: {
    autocomplete: any;
  }

  @Prop({ default: () => [], type: Array })
  private readonly value!: unknown[]

  @Prop({ default: 2 })
  private readonly maxVisible!: number

  @Prop({ default: false, type: Boolean })
  private readonly returnObject!: boolean

  @Prop()
  private readonly placeholder!: string

  @Prop()
  private readonly type!: string

  private search = null

  private resistomes = this.value

  private loading = false

  @Watch('search')
  private readonly debouncedGetResistomes = debounce(this.getResistomes, 750);

  private async getResistomes(search: string) {
    if (!search) {
      this.resistomes = []
      return
    }

    this.loading = true
    try {
      const { data } = await BacteriaFilterService.getResistomes(search, this.type)

      this.resistomes = Object.entries(data).reduce((acc: unknown[], [k, v]) => {
        if (Array.isArray(v) && v.length) {
          acc.push({ header: this.$t(`bacteria.resistome.${k}`) })
          return [
            ...acc,
            ...v,
            { divider: true },
          ]
        }

        return acc
      }, [])
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  private removeResistome(index: number) {
    const deleteCount = (index === this.maxVisible) ? Infinity : 1
    const copyValue = [...this.value]

    copyValue.splice(index, deleteCount)

    this.$emit('input', copyValue)
  }

  handleTouchOutside(event: Event) {
    const target = event.target as Element
    const { autocomplete } = this.$refs

    try {
      if (autocomplete.isFocused && !target.closest('.v-autocomplete__content')) {
        autocomplete.isFocused = false
      }
    } catch (_) { /** */ }
  }

  mounted() {
    document.body.addEventListener('touchmove', this.handleTouchOutside)
  }

  destroyed() {
    document.body.removeEventListener('touchmove', this.handleTouchOutside)
  }
}
